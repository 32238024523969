@import "palettes";
@import "theme";
@import "mixins";

.background {
  position: relative;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-image: var(--background);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    opacity: 1;
    // filter: blur(5px);
    z-index: -1;
  }

  &[data-dark="false"] {
    background: $gray-100;
    color: $black;
  }

  &[data-dark="true"] {
    background: $gray-900;
    color: $white;
  }
}
